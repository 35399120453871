import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'

// 模块功能升级包下载
export function modleupdatepackageurl(data) {
    var parata = []
    for (let i in data) {
        parata.push(i + "=" + data[i])
    }


    return `/form/modleupdate/package?` + parata.join("&");

}

//表单功能列表
export function Formxlist(query) {
    var data = {
        page: query.page,
        psize: query.psize,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)
    return request({
        url: '/form/model/list',
        method: 'post',
        data: data
    })

}



//数据库表
export function getdbtablelist(query) {
    var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)
    return request({
        url: '/form/model/dbtablelist',
        method: 'post',
        data: data
    })

}
//表字段
export function dbtablecolumns(tablename) {
    return request({
        url: '/form/model/dbtablecolumns',
        method: 'get',
        params: { tablename: tablename  }
    })

}

//删除表单功能
export function FormxDel(ids) {
    return request({
        url: '/form/model/del',
        method: 'post',
        data: { ids: ids + "" }
    })

}
//查看表单功能
export function FormxGet(id) {

    return request({
        url: '/form/model/get?id=' + id,
        method: 'get',
    })

}
//保存
export function FormxSave(data) {
    return request({
        url: '/form/model/save',
        method: 'post',
        data: data
    })

}

//新增
export function FormxAdd(data) {
    return request({
        url: '/form/model/add',
        method: 'post',
        data: data
    })

}
//生成菜单
export function FormxCreatemenu(data) {
    return request({
        url: '/form/model/createmenu',
        method: 'post',
        data: data
    })

}
 //生成代码
export function FormxGencode(data) {
    return request({
        url: '/form/model/gencode',
        method: 'post',
        data: data
    })

}
  //保存表单模版
export function FormxSaveformtpl(data) {
    return request({
        url: '/form/model/saveformtpl',
        method: 'post',
        data: data
    })

}

export function Formxformtpllist(query) {
    var data = {
        page: query.page,
        psize: query.psize,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)

    return request({
        url: '/form/model/formtpllist',
        method: 'post',
        data: data
    })

}

//保存导出模版
export function FormxSavedataexporttpl(  data) {
    return request({
        url: '/form/model/savedataexporttpl',
        method: 'post',
        data: data
    })

}

//导出模版
export function Formxdataexporttpllist(query) {
    var data = {
        page: query.page,
        psize: query.psize,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)

    return request({
        url: '/form/model/dataexporttpllist',
        method: 'post',
        data: data
    })

}



