import { mapGetters } from "vuex";
import {
  getFieldWidgetByName,
  deepClone,
  getAllFieldWidgets,
} from "@/vform/utils/util";
import * as FormM from "@/api/form/model";
import * as Formx from "@/api/form/data";
// import { getToken } from "@/utils/auth";
import { staticUrl } from "@/config";
import {
  FormxShowForminfo,
  FormxSavedataFilterTpl,
  FormxGetdataFilterTpl,
} from "@/api/form/data";
import { execModel } from "@/api/core/data/datasource/model";
import { customFields } from "@/vform/components/form-designer/widget-panel/widgetsConfig.js";
import { loadExtension } from "@/vform/extension/extension-loader";
export const form = {
  directives: {},
  data() {
    return {
      formfieldtype: {}, //表单字段类型
      defsort: {
        id: "desc",
      },

      // 查询参数
      queryParams: {
        fieldval: {},
        sortinfo: null,
        querytype: {},
        pageNum: 1,
        pageSize: 10,
      },
      //字典库数据
      DictsData: {},
      //数据源数据
      DsData: {},
      isFilterVisible: false,
      oldQueryParams: {},
      islogicdelTips: "是否确认删除所选数据项",
      fieldlist_update: [],
      fieldlist_insert: [],
    };
  },
  computed: {
    ...mapGetters({
      accessToken: "user/accessToken",
    }),
  },
  watch: {},
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {
    handleToolBtnAction(item, row) {
      switch (item.name) {
        case "downqrcode":
          this.$confirm("是否下载二维码？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            let queryParams = deepClone(this.queryParams);
            queryParams.extaction = { downqrcode: "1" };

            Formx.FormxShowList(this.mid, queryParams).then((response) => {
              let downloadfile = response.data.downloadfile;
            //   console.log("handleToolBtnAction downloadfile", downloadfile);
              let downloadUrl = location.origin + downloadfile.path;
              if (downloadUrl) {
                window.open(downloadUrl);
              }
            });
          });
          break;
      }
    },
    handleBtnAction(item, row) {
      // console.log("row",row)
      switch (item.name) {
        case "qrcode":
          let text =
            "http://scwap.connectbilian.com/#" +
            item.url +
            "?formId=" +
            this.mid +
            "&id=" +
            row.id +
            (item.title ? "&title=" + item.title : "") +
            item.extparam;

          this.getQRcode(text);
          this.showviewqrcodeinfourl = true;
          break;
        case "opendialog":
          this.showformdialogmid = item.mid;
          for (var f in item.queryparam) {
            item.queryparam[f] = item.queryparam[f].replaceAll(
              "{{id}}",
              row.id
            );
          }
          this.showformdialogqueryparam = item.queryparam;
          this.showformdialog = true;
          this.$nextTick(() => {
            this.$refs.formdatadialog && this.$refs.formdatadialog.initData();
          });
          break;
      }
    },

    //设置插入字段
    setInsertfieldshow(tformdata) {
      if (this.fieldlist_insert != null && this.fieldlist_insert.length > 0) {
        this.setfiledshow(this.fieldlist_insert, tformdata);
      }
    },
    setUpdatefieldshow(tformdata) {
      if (this.fieldlist_update != null && this.fieldlist_update.length > 0) {
        this.setfiledshow(this.fieldlist_update, tformdata);
      }
    },

    setfiledshow(fieldlist, tformdata) {
      let allfields = getAllFieldWidgets(tformdata.widgetList, true, false);
      let allfieldar = allfields.map((item) => item.name);

      for (let ii in allfieldar) {
        var fn = allfieldar[ii];
        var widgetSchema = getFieldWidgetByName(
          tformdata.widgetList,
          fn,
          true,
          true
        );
        if (widgetSchema != null) {
          var hide = fieldlist.indexOf(fn) == -1;
          //  表单没有设置隐藏的话
          if (widgetSchema.options.hidden == false) {
            widgetSchema.options.hidden = hide;
          }
        }
      }
    },
    //订阅组件间的消息
    subscribeComponentsMsg(skey, callback) {
      this.Pubsub.unsubscribe(skey); //防止重复订阅
      this.Pubsub.subscribe(skey, callback);
    },
    reformatformdata(formvalue) {
      let array = Object.keys(formvalue);
      for (let i in array) {
        let onefield = array[i];

        let fieldtype =
          (this.formfieldtype[onefield] && this.formfieldtype[onefield].type) ||
          "";
        let multiple =
          (this.formfieldtype[onefield] &&
            this.formfieldtype[onefield].multiple) ||
          false;

        //把图片返回列表解析成Object
        if (
          (fieldtype == "picture-upload" || fieldtype == "file-upload") &&
          formvalue[onefield] &&
          typeof formvalue[onefield] === "string"
        ) {
          try {
            formvalue[array[i]] = JSON.parse(formvalue[onefield]);
            for (let j = 0; j < formvalue[array[i]].length; j++) {
              let fileData = formvalue[array[i]][j];
              if (typeof fileData === "object" || fileData.url) {
                formvalue[array[i]][j] = {
                  uid: fileData.url,
                  name: fileData.url,
                  url:
                    fileData.url.indexOf("http") > -1
                      ? fileData.url
                      : staticUrl + fileData.url,
                  hash: fileData.hash,
                };
              }
              //   else {
              //     formvalue[array[i]][j] = {
              //       uid: fileData,
              //       name: staticUrl + fileData,
              //       url: staticUrl + fileData,

              //     };
              //   }
            }
          } catch (error) {
            console.warn("表单内容转为对象出错 字段(" + onefield + "):", error);
          }

          // if (formvalue[onefield].length) {
          //     formvalue[onefield].forEach((item) => {
          //         if (item.response) {
          //             item.url = item.response.data
          //                 ? item.response.data.url
          //                 : "";
          //         }
          //     });
          // }
        }

        //多选转义

        if (
          fieldtype == "checkbox" ||
          (multiple && fieldtype == "select") || //多选的select才需要
          fieldtype == "cascader" ||
          fieldtype == "sub-form" ||
          fieldtype == "date-range" ||
          fieldtype == "user-select"
        ) {
          if (
            formvalue[onefield] &&
            typeof formvalue[onefield] === "string" &&
            formvalue[onefield] != ""
          ) {
            try {
              formvalue[onefield] = JSON.parse(formvalue[onefield]);
            } catch (error) {
              console.warn(
                "表单内容转为对象出错 字段(" + onefield + "):",
                error
              );
              // formvalue[onefield] = [];
            }
          } else {
            formvalue[onefield] = [];
          }
        }
      }
      return formvalue;
    },
    settagtitle(title) {
      if (this.propsmid != null) {
        return false;
      }
      this.$route.meta.title = title;
      document.title = title;
      for (var i in this.$router.app.$store.state.tabsBar.visitedRoutes) {
        var onetag = this.$router.app.$store.state.tabsBar.visitedRoutes[i];
        if (onetag.path == this.$route.path) {
          this.$router.app.$store.state.tabsBar.visitedRoutes[i].title = title;
          return true;
        }
      }
      // this.$router.app.$store.state.tagsView.visitedViews[1].title="sdfsdfs"
    },
    //加载表单信息
    getforminfo(mid, isCustom, list = [],IsCreateTips=true) {
      this.dataList = [];
      if (!customFields || !customFields.length) {
        loadExtension();
      }
      FormxShowForminfo(mid)
        .then((rs) => {
          if (
            rs.data.forminfo.IsCreate &&
            rs.data.forminfo.IsCreate * 1 === 0&&IsCreateTips
          ) {
            this.$message.warning(
              "表单实体表尚未生成,请在表单配置点击 保存并应用 按钮"
            );
          }
          if (rs.data.forminfo.Category * 1 === 1) {
            this.settagtitle(rs.data.forminfo.ModuleName);
          }

          let field_for_list = rs.data.field_for_list;
          let field_for_query = rs.data.field_for_query;
          this.fieldlist_update = rs.data.update;
          this.fieldlist_insert = rs.data.insert;

          this.model_info = rs.data.forminfo;

          var tableColumn = [];
          var oprstrar = {};
          var rules = {};
          var DictsTypes = new Set();
          var DsT = new Set();

          var Formresource = JSON.parse(this.model_info.Formresource);
          if (
            typeof Formresource.formConfig.islogicdel === "boolean" &&
            !Formresource.formConfig.islogicdel
          ) {
            this.islogicdelTips = "删除后无法找回，是否确认删除所选数据项?";
          }

          this.listconf = Formresource["listconf"]
            ? Formresource["listconf"]
            : null;
          if (this.listconf && this.listconf.pagination) {
            this.queryParams.pageSize = this.listconf.pagination.deflimit * 1;
          }

          let allfields = getAllFieldWidgets(
            Formresource.widgetList,
            false,
            true
          );
          // console.log("allfields", allfields.map(item => item.name))
          //处理字典库 数据源 原始选项数据
          for (let dict in allfields) {
            let onefield = allfields[dict].field.options;
            this.formfieldtype[onefield.name] = {
              type: allfields[dict].type,
              multiple: onefield.multiple || false,
            }; //记录表单字段类型

            if (onefield["dicttype"] != null && onefield["dicttype"] != "") {
              DictsTypes.add(onefield["dicttype"]);
            }

            if (onefield["dsConf_dstid"] != null) {
              if (onefield["dsConf_dstid"] != "") {
                DsT.add(onefield["dsConf_dstid"]);
              }
            }

            if (onefield["optionItems"] != null) {
              let tmpoptionItems = deepClone(onefield["optionItems"]);
              for (let ik in tmpoptionItems) {
                if (tmpoptionItems[ik].value != null) {
                  tmpoptionItems[ik].value = tmpoptionItems[ik].value + ""; //转为字符串
                }
              }
              this.org_optionItems[onefield.name] = tmpoptionItems;
            }
          }

          if (Formresource.showpkfield) {
            tableColumn.push({
              field: "id",
              title: "ID",
              sortBy: "desc",
              sortable: true,
              width: 60,
              fixed: "left",
            });
          }
          //处理查询表单
          var queryfields = [];

          for (var j in field_for_query) {
            let colq = deepClone(field_for_query[j].fieldinfo.field);
            colq["options"]["required"] = false;

            queryfields.push(colq);
            var defaultValue = colq.options.defaultValue || "";

            if (
              defaultValue &&
              typeof defaultValue == "string" &&
              defaultValue.indexOf("${") > -1
            ) {
              delete colq.options.defaultValue;
            }

            if (colq.options["querytype"] != null) {
              this.queryParams.querytype = this.queryParams.querytype || {};
              this.queryParams.querytype[colq["options"]["name"]] =
                colq.options["querytype"];
              if (defaultValue) {
                //查询不设置默认值
                this.queryParams.fieldval[colq["options"]["name"]] = "";
                // defaultValue;
              }
            }
          }
          this.oldQueryParams = JSON.stringify(this.queryParams);
          var queryformData = this.reformatQueryForm(
            queryfields,
            Formresource.formConfig
          );
          this.queryformDataorgstr = JSON.stringify(queryformData);

          this.istree =
            Formresource.listconf && Formresource.listconf.liststyle == "2";

          for (let k in Formresource.FieldWidgets) {
            let onef = Formresource.FieldWidgets[k]["field"]["options"];
            if (
              onef["istreeparent"] != null &&
              onef["istreeparent"] == true &&
              this.istree
            ) {
              this.treeConfig["rowField"] = "id";
              this.treeConfig["parentField"] = onef["name"];
              break;
            }
          }

          for (var i in field_for_list) {
            let col = field_for_list[i].fieldinfo.field;

            let defcol = {
              field: col.options.name,
              title: col.options.label,
              sortable: col.options.showsortbtn,
              minWidth: 100,
              treeNode: col.options.istreelabel && this.istree,
              form_type: col.type === "date" ? 3 : "",
            };
            if (defcol["treeNode"] == true) {
              this.treeConfig["treeNode"] = defcol.field;
            }
            if (
              (col.options.dicttype != null && col.options.dicttype != "") ||
              col.options["optionItems"] != null
            ) {
              let dt = col.options.dicttype;

              let dfieldname = col.options["name"];

              defcol.slots = {
                default: "default" + dfieldname,
              };
              defcol.slotsFun = {
                funName: "NewdictFormat",
                key: dfieldname,
                dt: dt,
              };
              //判断是否有数据源，没有数据源则取默认参数
              if (dt) {
                defcol.dictType = dt;
              } else if (col.options.dsEnabled && col.options.dsConf_dstid) {
                defcol.dsEnabled = col.options.dsEnabled;
                defcol.dsConf_dstid = col.options.dsConf_dstid;
                defcol.dsConf_dst_label = col.options.dsConf_dst_label;
                defcol.dsConf_dst_value = col.options.dsConf_dst_value;
              } else {
                defcol.options = this.org_optionItems[dfieldname];
                defcol.options_value = "value";
                defcol.options_label = "label";
                defcol.form_type = 2;
              }
            }

            if (
              col.type === "dep-select" ||
              col.type === "personnel-select" ||
              col.type === "user-select"
            ) {
              defcol.formatter = function ({ cellValue, row, column }) {
                let selectObj ={}
                 
                try {
                    selectObj=  cellValue && typeof cellValue === "string"
                    ? JSON.parse(cellValue)
                    : {};
                } catch (error) {
                    return cellValue
                }
                if (col.type === "dep-select") {
                  defcol.form_type = 4;
                  {
                    return selectObj.dep
                      ? selectObj.dep.map((o) => o.deptName).join("、")
                      : "";
                  }
                }
                {
                  if (col.type === "personnel-select") {
                    defcol.form_type = 5;
                    return selectObj.user
                      ? selectObj.user.map((o) => o.nickName).join("、")
                      : "";
                  }
                }

                //return '<div class="el-badge item">'+row.title+'<sup class="el-badge__content is-dot"></sup></div>'
              };
            }
            if (col.type === "date-range") {
              defcol.form_type = 3;
              let that = this;
              defcol.slots = {
                default: function (e) {
                  return that.dateRangeFormat(e);
                },
              };
            }

            if (defcol.field.indexOf("sign_")!=-1) {
            //   defcol.form_type = 3;
              let that = this;
              defcol.slots = {
                default:  "default" + col.options["name"],
              };
              defcol.slotsFun = {
                funName: "signFormat",
                key: col.options["name"],
                dt: col.options["name"],
              };
            }
            if (
              defcol.field === "create_time" ||
              defcol.field === "update_time"
            ) {
              defcol["formatter"] = "formatDate";
            }

            tableColumn.push(defcol);
          }
          if (typeof isCustom === "boolean" && isCustom) {
            if (list.length) {
              this.tableColumn = list;
            }
          } else {
            this.tableColumn = tableColumn;
          }
          Formresource = null;

        //   console.log("DsT.keys()", DsT.keys(), DsT.size);
          var dssize = DsT.size;
          var dsstep = 0;
          for (let dstid of DsT.keys()) {
            execModel(dstid, JSON.stringify({ __max_count__: 999 }))
              .then((res) => {
                if (res.data && res.data.length > 0) {
                  this.DsData["ds" + dstid] = deepClone(res.data);
                  //   console.log("ds" + dstid, this.DsData["ds" + dstid]);
                }
                dsstep++;

                // console.log("dsstep",dsstep,dssize)
                if (dsstep == dssize) {
                  this.binddicttotablecolumn();
                }
              })
              .catch((error) => {
                console.log("execModel err", error);
                dsstep++;
              });
          }
          //批量加载字典库数据

          for (let item of DictsTypes.keys()) {
            this.getDicts(item).then((response) => {
              let dictvalue = response.data.values || [];
              this.DictsData[item] = dictvalue.map((it) => {
                return {
                  label: it.value,
                  value: it.key,
                  label_color: it.label_color,
                };
              });
            });
          }
          this.queryformData = JSON.parse(this.queryformDataorgstr);
          console.log(this.queryformData,'this.queryformData')
            this.$nextTick((_) => {
              setTimeout((_) => {
                this.binddicttotablecolumn();
              }, 800);
            });

          // if (this.Quitfilter_keys.length > 0) {
          //   this.leftrolspan = 3;
          // } else if (this.model_info.Category == "5") {
          //   this.leftrolspan = 4;
          // } else {
          //   this.leftrolspan = 0;
          // }
        })
        .then(() => {
          this.queryParams.pageNum = 1;

          setTimeout(() => {
            this.getList(mid);
          }, 300);
        });
    },
    //时间翻译
    dateRangeFormat(row) {
      let field = this.tableColumn[row.columnIndex-1].field;
      // console.log("dateRangeFormat row this.tableColumn", row,this.tableColumn);

      if (typeof row.row[field] === "string" && row.row[field] != ""){
        try {
            var j=row.row[field]?JSON.parse(row.row[field]):''
        
            return j&&j.length>0?j.join(" 至 "):''
       
        } catch (error) {

            return ""
        }
        

      }
        
    },
    signFormat(dt,cellValue,fildname){
        if(cellValue){
          return `<img src="${cellValue}" style="width:100px;height:100px;"/>`
        }
        return ''
    },
    //数据源

    //绑定字典库
    NewdictFormat(dictType, cellValue, fildname) {
      let tdata = dictType ? this.DictsData[dictType] : null;

      if (!tdata) {
        let Formresource = JSON.parse(this.model_info.Formresource);
        let widgetList =
          Formresource.FieldWidgets && Formresource.FieldWidgets.length
            ? Formresource.FieldWidgets.filter((o) => o.name === fildname)
            : [];
        let onef = widgetList.length > 0 ? widgetList[0].field.options : null;
        if (onef && onef.dsEnabled) {
          var dstid = onef.dsConf_dstid;
          var dstattr = onef.dsConf_dstattr;
          if (dstid != null && dstid != "") {
            //区分多项和单项默认值
            if (onef.hasOwnProperty("optionItems")) {
              //多行数据
              var optionlist = [];
              var dsConf_dst_label = onef.dsConf_dst_label || "";
              var dsConf_dst_value = onef.dsConf_dst_value || "";
              var labelKey = onef.labelKey || "label";
              var valueKey = onef.valueKey || "value";
              if (labelKey == "") {
                labelKey = "label";
              }
              if (valueKey == "") {
                valueKey = "label";
              }

              if (dsConf_dst_label == "" || dsConf_dst_value == "") {
                console.warn("指定数据源签名或指定数据源值名为空,忽略");
                return;
              }
              dsConf_dst_label = dsConf_dst_label.split(":")[0];
              dsConf_dst_value = dsConf_dst_value.split(":")[0];

              for (let ik in this.DsData["ds" + dstid]) {
                let dsrow = this.DsData["ds" + dstid][ik];
                let opt = {};
                opt[labelKey] = dsrow[dsConf_dst_label];
                opt[valueKey] = dsrow[dsConf_dst_value];

                optionlist.push(opt);
              }
              tdata = deepClone(optionlist);
              let field_i = this.tableColumn.findIndex(
                (o) => o.field == onef.name
              );
              if (field_i > -1) {
                this.tableColumn[field_i].options = deepClone(optionlist);
                this.tableColumn[field_i].options_label = labelKey;
                this.tableColumn[field_i].options_value = valueKey;
              }
              //   if (cellValue && this.tableColumn[cellValue]) {
              //     this.tableColumn[cellValue].options = deepClone(optionlist);

              //     this.tableColumn[cellValue].options_label = labelKey;
              //     this.tableColumn[cellValue].options_value = valueKey;
              //   }
            } else if (dstattr != null && dstattr != "") {
              var defaultValue = null;
              var attrf = dstattr.split(":")[0];

              defaultValue = this.DsData["ds" + dstid][0]
                ? this.DsData["ds" + dstid][0][attrf]
                : null;

              onef.defaultValue = defaultValue;
            }
          }
        } else {
          //没有字典库则尝试去读表单里面的选项
          tdata = this.org_optionItems[fildname];
        }
      }

      //如果有字典库
      if (tdata) {
        let values = [];

        try {
          //判断是否存在
          values = cellValue ? JSON.parse(cellValue) : [];
          //判断是否是数组
          if (!Array.isArray(values)) {
            values = values.length > 1 ? values.split(",") : [cellValue];
          }
        } catch (error) {
          values = cellValue
            ? cellValue.length > 1
              ? cellValue.split(",")
              : [cellValue]
            : [];

          // formvalue[onefield] = [];
        }
        if (!values.length && cellValue * 1 === 0) {
          values = [0];
        }
        let labels = "";
        for (let i = 0; i < values.length; i++) {
          const v = values[i];
          for (let j = 0; j < tdata.length; j++) {
            const item = tdata[j];
            if (v == item.value) {
              let ismuilt = values.length > 1;
              let splitchar = "";

              if (ismuilt && i > 0) {
                splitchar = ",";
              }

              let label_color = item.label_color ? item.label_color : "";
              labels += `<span style = "color: ${label_color}" > ${
                splitchar + item.label
              } </span>`;

              break;
            }
          }
        }

        return labels;
      } else {
      }
      return `< span > ${cellValue} < /span>`;
    },
    toggleExpandChangeEvent() {
      // const treeExpandRecords = this.$refs.xGrid.getTreeExpandRecords();
      // const expandRowKeys = [];
      // for (let i = 0; i < treeExpandRecords.length; i++) {
      //     expandRowKeys.push(treeExpandRecords[i].id);
      // }
      // this.treeConfig.expandRowKeys = expandRowKeys;
    },

    //根据表单配置生成查询表单
    reformatQueryForm(fields, formConfig) {
      formConfig.labelPosition = "left";
      formConfig.formCache = false;
      formConfig.labelWidth = 68;
      var gridid = "grid" + Math.floor(Math.random() * 900000 + 9000);
      var onecol = {
        type: "grid-col",
        category: "container",
        icon: "grid-col",
        internal: true,
        widgetList: [],
        options: {
          querytype: "",
          showsortbtn: false,
          name: "gridCol44080",
          hidden: false,
          span: 8,
          offset: 0,
          push: 0,
          pull: 0,
          responsive: false,
          md: 12,
          sm: 12,
          xs: 12,
          customClass: "",
        },
        id: "grid-col-44080",
      };
      var topwidgetList = [
        {
          type: "grid",
          alias: "",
          category: "container",
          icon: "grid",
          cols: [],
          options: {
            querytype: "",
            showsortbtn: false,
            name: gridid,
            hidden: false,
            gutter: 5,
            span: 8,
            colHeight: null,
            customClass: [],
          },
          id: gridid,
        },
      ];
    //   console.log(fields,'fields',topwidgetList);
      for (let i in fields) {
        var onef = fields[i];
        if (
          onef.options.label.replace(/\s+/g, "").length > 3 &&
          onef.options.labelIconClass
        ) {
          onef.options.labelWidth = "80";
        } else if (onef.options.label.replace(/\s+/g, "").length > 5) {
          onef.options.labelWidth = "100";
        } else if (onef.options.label.replace(/\s+/g, "").length > 8) {
          onef.options.labelWidth = "120";
        }
        onef.options.labelAlign = "label-left-align";
        onef.options.displayStyle = "inline";

        onef.options.defaultValue = undefined; //去掉默认值
        onef.options.disabled = false; //去掉禁用
        onef.options.hidden = false; //去掉隐藏

        onecol.id = "gridCol" + Math.floor(Math.random() * 900000 + 9000);
        onecol.options.name = onecol.id;

        onecol.widgetList = [deepClone(onef)];

        topwidgetList[0].cols.push(deepClone(onecol));
        // console.log("onef.options", onef.options);
      }

      var rs = {
        widgetList: topwidgetList[0].cols.length > 0 ? topwidgetList : [],
        formConfig: formConfig,
      };

      return rs;
    },
    //保存导出模板
    saveExportTemplate(res) {
      if (res.template_name == null || res.template_name == "") {
        this.msgError("模板名称不能为空!");
        return false;
      }
      var data = {
        name: res.template_name,
        mid: this.mid,
        fieldinfo: JSON.stringify(res.list),
      };
      var postdata = {
        row: JSON.stringify(data),
      };

      FormM.FormxSavedataexporttpl(postdata).then((_) => {
        this.msgSuccess("操作成功");
        this.getexportTemplateList();
      });
    },

    //获取导出模板
    getexportTemplateList() {
      var param = {
        page: 1,
        psize: 20,
        fieldval: { mid: this.mid },
        sortinfo: { id: "desc" },
      };
      FormM.Formxdataexporttpllist(param).then((res) => {
        this.exportTemplateList = res.data.list;
      });
    },

    //控制显示按钮
    switchshowbtn(btn) {
        // console.log("switchshowbtn this.listconf.showbtns", btn,this.listconf.showbtns);
      return this.listconf == null || this.listconf.showbtns.indexOf(btn) != -1;
    },

    //绑定字典数据到表单字段
    binddicttotablecolumn(tformdata) {
      if (tformdata) {
        // console.log("tformdata.widgetList", tformdata.widgetList);
        for (let i in tformdata.FieldWidgets) {
          let onefield = tformdata.FieldWidgets[i];
          if (
            onefield.type == "sub-form" &&
            onefield.field.widgetList.length > 0
          ) {
            for (let jj in onefield.field.widgetList) {
              let subfonef = onefield.field.widgetList[jj].options;

              this.setonefieldds(tformdata.widgetList, subfonef);
            }
          }
          let onef = tformdata.FieldWidgets[i].field.options;

          this.setonefieldds(tformdata.widgetList, onef);
        }

        return tformdata;
      }
        //  console.log("binddicttotablecolumn formdata", this.queryformData, this.queryformData.widgetList)
      if (
        this.queryformData &&
        this.queryformData.widgetList &&
        this.queryformData.widgetList.length
      ) {
        // console.log(" this.queryformData.widgetList",  this.queryformData.widgetList);

        for (let i in this.queryformData.widgetList[0].cols) {
          var onef =
            this.queryformData.widgetList[0].cols[i]["widgetList"][0].options;

          this.setonefieldds(
            this.queryformData.widgetList[0].cols[i]["widgetList"],
            onef
          );

          //   if (onef.options.dicttype != null && onef.options.dicttype != "") {
          //     let dicttype = onef.options.dicttype;
          //     onef["options"]["optionItems"] = deepClone(
          //       this.DictsData[dicttype]
          //     );
          //   }
        }

        if (this.$refs.queryform) {
          this.$refs.queryform.reloadOptionData();
        }
      }
    },
    setonefieldds(widgetList, oneoption) {
      let fn = oneoption.name;

      //某个组件
      var widgetSchema = getFieldWidgetByName(widgetList, fn, true, true);
      //console.log("widgetSchema oneoption", widgetSchema,oneoption);

      if (oneoption.dicttype != null && oneoption.dicttype != "") {
        if (
          !!widgetSchema &&
          !!widgetSchema.options &&
          widgetSchema.options.hasOwnProperty("optionItems")
        ) {
          let cloneoptions = deepClone(this.DictsData[oneoption.dicttype]);
          widgetSchema.options.optionItems = cloneoptions ? cloneoptions : [];
          // console.log("widgetSchema.options.optionItems", widgetSchema.options.optionItems,oneoption.dicttype);
        }
      } else {
        //数据源

        var dstid = oneoption.dsConf_dstid;
        var dstattr = oneoption.dsConf_dstattr;
        var dsEnabled =
          oneoption.dsEnabled == null ||
          (oneoption.dsEnabled != null && oneoption.dsEnabled); //数据源开关
        // console.log("数据源  ",oneoption, dstid,dsEnabled)

        if (dstid != null && dstid != "" && dsEnabled) {
          //  console.log("数据源 oneoption", oneoption)

          //区分多项和单项默认值
          if (widgetSchema.options.hasOwnProperty("optionItems")) {
            //多行数据
            var optionlist = [];
            var dsConf_dst_label = oneoption.dsConf_dst_label || "";
            var dsConf_dst_value = oneoption.dsConf_dst_value || "";
            var labelKey = oneoption.labelKey || "label";
            var valueKey = oneoption.valueKey || "value";
            if (labelKey == "") {
              labelKey = "label";
            }
            if (valueKey == "") {
              valueKey = "label";
            }

            if (dsConf_dst_label == "" || dsConf_dst_value == "") {
              console.warn("指定数据源签名或指定数据源值名为空,忽略");
              return;
            }
            dsConf_dst_label = dsConf_dst_label.split(":")[0];
            dsConf_dst_value = dsConf_dst_value.split(":")[0];

            for (let ik in this.DsData["ds" + dstid]) {
              let dsrow = this.DsData["ds" + dstid][ik];
              let opt = {};
              opt[labelKey] = dsrow[dsConf_dst_label];
              opt[valueKey] = dsrow[dsConf_dst_value];
              opt["orgdata"] = dsrow;

              optionlist.push(opt);
            }

            // console.log("最终读取到的 optionlist",optionlist,dstid,this.DsData,this.DsData["ds" + dstid]);

            widgetSchema.options.optionItems = deepClone(optionlist);
          } else if (dstattr != null && dstattr != "") {
            var defaultValue = null;
            var attrf = dstattr.split(":")[0];

            defaultValue = this.DsData["ds" + dstid][0]
              ? this.DsData["ds" + dstid][0][attrf]
              : null;

            widgetSchema.options.defaultValue = defaultValue;
          }
        }
      }

      //树形表上级
      if (this.istree && oneoption["istreeparent"] === true) {
        widgetSchema.options["treeData"] = this.dataList;
      }
    },

    //数据表变动事件
    tableChange(e) {
      switch (e.name) {
        case "filter":
          let ftdata = e.data;

          for (let i in ftdata) {
            let oneft = ftdata[i];
            this.queryParams.querytype[oneft.field] = oneft.querytype;
            this.queryParams.fieldval[oneft.field] = oneft.value;
          }
          this.queryParams.pageNum = 1;

          this.getList(this.mid);

          break;
        case "srot":
          if (JSON.stringify(e.data) == "{}") {
            this.queryParams.sortinfo = this.defsort;
          } else {
            this.queryParams.sortinfo = JSON.parse(JSON.stringify(e.data));
          }

          this.getList(this.mid);
          break;
        case "template":
          this.saveTemplate(e);
          break;
      }
    },
    //保存过滤模板
    saveTemplate(res) {
      this.isFilterVisible = false;
      if (res.template_name == null || res.template_name == "") {
        this.msgError("模板名称不能为空!");
        return false;
      }
      var data = {
        name: res.template_name,
        mid: this.mid,
        fieldinfo: JSON.stringify(res.data),
      };
      var postdata = {
        row: JSON.stringify(data),
      };

      FormxSavedataFilterTpl(postdata)
        .then((_) => {
          this.msgSuccess("操作成功");
          this.getFilterTemplateList();
          this.isFilterVisible = true;
        })
        .catch((error) => {
          this.isFilterVisible = true;
        });
    },
    getFilterTemplateList() {
      var param = {
        page: 1,
        psize: 100,
        fieldval: { mid: this.mid },
        sortinfo: { id: "desc" },
      };
      FormxGetdataFilterTpl(param).then((res) => {
        this.filterTemplateList = res.data.list;
      });
    },

    //排序变动
    sortChange({ column, property, order, sortBy, sortList }) {
      if (column.sortList.length > 0) {
        let st = {};

        st[column.property] = column.order;

        this.queryParams.sortinfo = st;
      } else {
        this.queryParams.sortinfo = JSON.parse(JSON.stringify(this.defsort));
      }
      this.getList(this.mid);
    },

    checkboxChangeEvent({ records }) {
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },
    handleSizeChange({ currentPage, pageSize }) {
      this.queryParams.pageNum = currentPage;
      if (this.queryParams.pageSize == pageSize) {
        this.getList(this.mid);
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },

    /** 重置按钮操作 */
    resetQuery() {
      // this.queryParams.dept_id = 0;
      //   this.queryParams.fieldval = {};
      this.queryParams = JSON.parse(this.oldQueryParams);
      this.dateRange = [];

      this.queryParams.pageNum = 1;
      this.queryformData = null;
      this.$nextTick(() => {
        this.queryformData = JSON.parse(this.queryformDataorgstr);
        // console.log(this.queryformData, "  this.queryformData");
      });

      this.$nextTick((_) => {
        this.binddicttotablecolumn();
      });

      this.getList(this.mid);
    },

    /** 搜索按钮操作 */
    handleQuery() {
      //console.log(this.$refs.queryform, '  this.$refs.queryform')
      this.$refs.queryform.getFormData().then((qdata) => {
        // console.log(qdata, "  qdata");
        this.queryParams.fieldval = {};
        for (var qfield in qdata) {
          var qvalue = qdata[qfield];
         
        //   console.log(typeof qvalue == "string" , qvalue , qvalue!= "", "  =====");

          if (typeof qvalue == "string" && qvalue && qvalue != "") {
           
            this.queryParams.fieldval[qfield] = qvalue;
            // if(qfield=="qbid"){
            //     this.qbid = qvalue
            // }
            // && qvalue * 1 === 0
          } else if (typeof qvalue == "number") {
          
            this.queryParams.fieldval[qfield] = qvalue;
          } else if (Array.isArray(qvalue) && qvalue) {
            this.queryParams.fieldval[qfield] = qvalue;
            this.queryParams.querytype[qfield] = "in";
          }
        }

        // this.qbid=this.queryParams.fieldval["qbid"]
        this.queryParams.pageNum = 1;
        // console.log(this.queryParams, "  this.queryParams");

        this.getList(this.mid);
      });
    },
  },
};
