import request from '@/utils/request'

// 查询数据源列表
export function listModel(query) {
  return request({
    url: '/datasource/model/list',
    method: 'get',
    params: query
  })
}

// 查询数据源详细
export function getModel(id) {
  return request({
    url: '/datasource/model/get?id=' + id,
    method: 'get'
  })
}

// 新增数据源
export function addModel(data) {
  return request({
    url: '/datasource/model/add',
    method: 'post',
    data: data
  })
}

// 修改数据源
export function updateModel(data) {
  return request({
    url: '/datasource/model/edit',
    method: 'post',
    data: data
  })
}

export function updateModelSchedule(data) {
  return request({
    url: '/datasource/model/editschedule',
    method: 'post',
    data: data
  })
}

export function enableModelSchedule(data) {
  return request({
    url: '/datasource/model/enableschedule',
    method: 'post',
    data: data
  })
}

// 删除数据源
export function delModel(id) {
  return request({
    url: '/datasource/model/del',
    method: 'post',
    data: {
      ids: id
    }
  })
}

// 预览数据
export function previewModel(data) {
  return request({
    url: '/datasource/model/preview',
    method: 'post',
    data: data
  })
}

// 执行获取数据
export function execModel(id, params) {
  return request({
    url: '/datasource/model/exec',
    method: 'post',
    data: {
      id: id,
      params: params
    }
  })
}

// 更新抽取数据
export function updateData(id, params) {
  return request({
    url: '/datasource/model/updateData',
    method: 'post',
    data: {
      id: id,
      params: params
    }
  })
}

//关键字
export function statistics() {
    return request({
      url: '/cms/statistics/data',
      method: 'get',
     
    })
  }


